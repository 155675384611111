import { Input } from 'antd'
import React, { FC, useCallback } from 'react'
import NumberInputFormat, { NumberFormatProps } from 'react-number-format'

import { InputProps } from './Input'

export type TNumberInputProps = NumberFormatProps<InputProps>

export const NumberInput: FC<TNumberInputProps> = React.forwardRef((props, ref) => {
    return (
        <NumberInputFormat
            customInput={Input}
            getInputRef={ref}
            decimalSeparator={','}
            allowNegative={false}
            thousandSeparator={' '}
            {...props}
        />
    )
})
