import { SelectProps as SelectPropsAntd } from 'antd'
import { DefaultOptionType as DefaultOptionTypeAntd } from 'rc-select/lib/Select'
import { FC } from 'react'

import { FormItem, FormItemProps } from '../FormItem'
import { Select } from './Select'

export type SelectProps = SelectPropsAntd & FormItemProps
export type DefaultOptionType = DefaultOptionTypeAntd

export const WrappedSelect: FC<SelectProps> = ({
    children,
    size = 'large',
    options,
    defaultActiveFirstOption,
    onChange,
    disabled,
    renderUneditable,
    placeholder,
    allowClear,
    defaultValue,
    mode,
    ...rest
}) => {
    const defaultRenderUneditable = (value: DefaultOptionType['value']) => {
        if (options) {
            const findedOption = options.find((option) => option.value === value)
            if (findedOption) {
                return findedOption.label
            }
        }
        return value
    }

    return (
        <FormItem
            {...rest}
            renderUneditable={
                renderUneditable ?? (defaultRenderUneditable as FormItemProps['renderUneditable'])
            }
        >
            <Select
                mode={mode}
                size={size}
                defaultActiveFirstOption={defaultActiveFirstOption}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                options={options}
                allowClear={allowClear}
                style={{ width: '100%' }}
                defaultValue={defaultValue}
                maxTagCount="responsive"
            >
                {children}
            </Select>
        </FormItem>
    )
}
