import { FC } from 'react'
import { InfoCircle } from '../../../Icons'
import styles from './FieldWarning.m.less'

interface IFieldWarningProps {
    label?: string
}

export const FieldWarning: FC<IFieldWarningProps> = ({ label }) => {
    return (
        <div className={styles.warning}>
            <div className={styles.icon}>
                <InfoCircle />
            </div>
            <span className={styles.label}>{label || ''}</span>
        </div>
    )
}
