// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".warning_bPLxD {\n  display: flex;\n  background: #e3f6ff;\n  padding: 9px 16px;\n  justify-content: flex-start;\n  align-items: center;\n  max-height: 44px;\n  border-radius: 16px;\n  margin-left: 15px;\n}\n.warning_bPLxD .label_IMPKu {\n  color: #555861;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n.warning_bPLxD .icon_sfPwA {\n  color: #007ef2;\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/components/Form/Warnings/FieldWarning.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AATA;EAWQ,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACR;AAhBA;EAmBQ,cAAA;EACA,kBAAA;AAAR","sourcesContent":[".warning {\n    display: flex;\n    background: #e3f6ff;\n    padding: 9px 16px;\n    justify-content: flex-start;\n    align-items: center;\n    max-height: 44px;\n    border-radius: 16px;\n    margin-left: 15px;\n\n    .label {\n        color: #555861;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 22px;\n    }\n\n    .icon {\n        color: #007ef2;\n        margin-right: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "warning_bPLxD",
	"label": "label_IMPKu",
	"icon": "icon_sfPwA"
};
export default ___CSS_LOADER_EXPORT___;
